import React from 'react';
import Layout from "../../components/layout";

const Channon = () => {
  return (<Layout>
    <h1>Channon</h1>
    <h2>Instructors</h2>
    <p><a href="#rachel">Rachel Whiting</a></p>

    <h2>Class Times</h2>
    <p><strong>Tuesday and Friday</strong><br/>
      <strong>6-12yrs:</strong> 4.30pm-5.30pm<br/>
      <strong>13yrs+:</strong> 5.45-6.45pm</p>

    <p><strong>Prices</strong><br/>
      <strong>Children:</strong> $8.00 / class</p>

    <p><strong>Location</strong><br/>
      <strong>The Channon Hall</strong>, Channon, NSW</p>
    <hr/>
    <a href="#top" id="rachel">Top</a>

    <h2>Sensei Rachel Whiting</h2>
    <p><strong>2nd Dan Goju Ryu</strong></p>

    <p>A month after my 14th birthday, in 1988, the first karate class opened up in my little hometown of
      Wamuran in Queensland. I attended, along with my two older brothers. One brother trained for a year
      before he'd had enough, and the other a couple more until he reached 5th kyu (green belt). I stayed on
      and I had two older brothers to contend with! As well as school bullies. My first instructor was Sensei
      David Houghton, and I thank him deeply for his tuition, guidance and encouragement in my first couple of
      years of training as a shy but secretly feisty teenage girl. He brought out the feisty in me, and gave
      my inner fire a place to be seen and heard! It was this happy discovery and grasping of my inner spirit
      that kept me coming back for more.</p>

    <p>At the age of 16 I moved towns and commenced training with Sensei Rob Williams in Morayfield. He
      commanded more serious training, no talking! I responded well to this, and at the age of 19 I received
      my Dansha (black belt) grading, out bush under Sensei Ravey, which included the completion of my first
      16 person fight. In this time I had assisted Sensei Williams to run several Onna Do (women's self
      defense) courses, and was an assistant instructor in the Dojo.</p>

    <p>I left for university in Townsville in 1993, and continued my personal training almost daily in my
      backyard. Before long, female friends were asking me to teach them, so I decided to run an Onna Do
      course myself through the university, at the age of 19. By the time I was 21 I had just finished my
      degree in Graphic Design and headed home to attempt my 2nd Dan grading in the bush again on another hot
      Summer's day in Kilcoy under Sensei Ravey. Basics, combinations, kata, bunkai, chishi, jo, ippon kumite,
      and of course the big finale of the 16 person fight, this time with 2 other women who were going for
      their 1st Dan gradings. Go the women and we passed! </p>

    <p>In 1996, the year after, some of our Morayfield Dojo students entered a National All Styles martial arts
      competition in the Gold Coast. We had never entered competition before and were all a bit nervous. Many
      of us came out with trophies at the end of the day, including myself with a First place in Women's Open
      Sparring. I was offered a place in the National competition later that year, but unfortunately couldn't
      afford the plane ticket.</p>

    <p>I moved into Brisbane and took up a full-time job washing cars at a 4WD car yard, which I loved! It was
      another opportunity to practice my karate training, getting into low shiko dachi (horse riding stance),
      keeping my body centred at all times, using my hips to whip my arms about, turning into zen kutsu dachi
      around the corners, maintaining breath control and pushing myself against the clock to get as many cars
      done each hour as I could. My bosses loved my work, and I know I was a source of amusement for the dodgy
      salesmen, who I sprayed regularly with the hose and fired my fiery eyes at whenever they tried to get
      too close.</p>

    <p>At this time, to continue my training I would race back from work, often on my pushbike, and take the
      train for an hour each way twice a week to Morayfield, where I continued as an assistant instructor
      under Sensei Williams. It was a long day and a late night and I absolutely loved it. For a short time
      while Sensei Williams visited Africa to instruct Goju Ryu students, I instructed the Morayfield Dojo on
      my own. </p>

    <p>As a sufferer of 'heat asthma' then (heat and hard exercise bring it on ie. gradings!), I was encouraged
      by a Chinese neighbour to accompany him to traditional Qi Gong classes in Brisbane, which he said would
      heal me of my asthma. Sceptical but curious, I gave it my best shot, and learnt the movements and the
      theory behind it at a private Chinese family's residence through his translation, no payment allowed. I
      was told, 2 hours/day for 100 days and the asthma would be gone. Hmmm, okay so I did it, an hour in the
      morning before work, an hour before bed, 100 days (and a few more to be sure!). Then off I went to do
      the test camping in my father's steep hills in hot weather, I filled up two 10 litre containers with
      fresh creekwater, one in each hand, and marched myself up the steep hill in front of me. I got to the
      top with clear open lungs, no closure, no wheezing, what??? Guess it works!</p>

    <p>During the several years as a senior level student before I went travelling in 1999, I enjoyed the
      monthly senior training sessions that Sensei Ravey held in Kilcoy. We would all car pool from Caboolture
      and head up for the drive into the hills, do a great session of hard training with other senior
      students, and relax together afterward for a while before heading home again. I really felt like part of
      a family, not just some collection of people who happened to be interested in martial arts training. We
      spoke of many aspects of training, and Sensei Ravey inspired me in many ways to look deeper into karate,
      including its spiritual side. During my time at university, I used every opportunity to direct my
      assignments towards my TOGKA passions, including painting the 5 precepts separately onto 5 sumi-e
      scrolls in Japanese traditional style with symbolic artwork, and creating a hanging shrine of Sensei
      Miyagi, all for use in the Dojo. These I presented to Sensei Ravey in thanks.</p>

    <p>Following an urge to explore Australia, I left Brisbane and my regular Goju-Ryu classes behind,
      maintaining almost daily personal training while I was on the road with my partner for a year, whom I
      taught. Arriving in Darwin at the end of 1999, I found an Aikido class and hence began my broadening
      exploration of the martial arts. In early 2000 I volunteered to work in East Timor, where the Indonesian
      army were still being €˜removed' by the Australian Armed Forces, to support their new independence as a
      nation. Here I tentatively trained when I could (very unstable military territory!), and came across the
      Indonesian martial art of Silat. I trained in this hard and aggressive style for a short time until I
      was asked to instruct some Goju Ryu students in the hills behind the village. I was stunned that there
      were Goju Ryu students at all here in East Timor! With no instructors left to train them, they trained
      by themselves. And so I taught classes twice a week on the paddocks above the rice fields, with the most
      dedicated, grateful, diligent and respectful students I have ever seen!</p>

    <p>I married my partner at the end of 2001, and after a brief stay in Woodford where I was able to train
      again with Sensei Williams and Sensei Ravey for a short time, we moved to northern NSW and set up home
      for 3 years. Here I gave birth to two beautiful children, and took up Tai Chi while my body healed and
      regained its strength after child-birth. I picked up Tai Chi very quickly, and was given permission to
      teach the 24 form Tai Chi to parents at my son's playgroup child in baby pouch or pulling at my pants! I
      simultaneously trained in Kung Fu's 5 animal forms with my Tai Chi instructor, as well as further
      training in the Jo. By 2005 we were off again to live in Darwin due to my partner's ill-health. </p>

    <p>Back in Darwin I found a Goju Ryu instructor who visited Okinawa bi-annually, and taught me a lot about
      balancing go' (hard) and ju' (soft) in my martial arts. I also spent every Tuesday evening at a friend's
      place sparring with 4 keen senior martial artists from varying styles. We shared our skills, sparred til
      we were done, and enjoyed the opportunity to try ourselves out against different styles. Iyengar Yoga
      became a part of my weekly regime during the next few years, and I reached Intermediate level,
      developing much more understanding of the science of the body through this technically demanding
      practice.</p>

    <p>After a marital breakdown I found myself living back in northern NSW in 2009, and very keen to pass on my
      Goju Ryu knowledge to my young children for their benefit. Fortunately, they have grabbed it with both
      hands so to speak! Hence began a regular class on the grass of my front yard. A friend of theirs asked
      to join in, then another two, and before I knew it I was moving into the local hall in The Channon with
      a full class of students in 2011, where I still teach. I maintain a focus on the Traditional' moral and
      spiritual aspects of Okinawan Goju-ryu Karate-do, concerned with developing people who can function in
      society to the benefit of society', as well as teaching balanced Go (hard) and Ju (soft) karate for the
      purposes of practical self-defense. I continue training with Sensei Ravey whenever I can, and undertake
      regular training with Sensei Simon Golland, 4th Dan Goju Ryu Instructor.</p>

    <p>My vision is to continue instructing at The Channon dojo and offering this precious gift for many years
      to come!</p>
  </Layout>);


}

export default Channon;
